import React from 'react';
import Nav from './components/Nav';
import Header from './components/Header';
import AboutMe from './components/AboutMe';
// import PhotoGallery from './components/PhotoGallery';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import './App.css';
import ProjectCardList from './components/ProjectCardList';
import UnderConstruction from './components/UnderConstruction';
import Footer from './components/Footer';

const loraFont = "'Lora', serif;";
const poppinsFont = "'Poppins', sans-serif";

const defaultTheme = createMuiTheme({
  palette : {
    primary : {
      main         : '#003e3b',
      contrastText : '#fff'
    },
    secondary : {
      main         : '#Ff5f66',
      contrastText : '#fff'
    }
  },
  typography : {
    fontFamily : loraFont,
    h1         : {
      fontFamily : poppinsFont,
      fontWeight : '700'
    },
    h2 : {
      fontFamily : poppinsFont,
      fontWeight : '700'
    },
    h3 : {
      fontFamily : poppinsFont,
      fontWeight : '700'
    },
    h4 : {
      fontFamily : poppinsFont,
      fontWeight : '500'
    },
    h5 : {
      fontFamily : poppinsFont,
      fontWeight : '500'
    }
  }
});

const theme = responsiveFontSizes(defaultTheme);

export default function App () {
  return (
    <ThemeProvider theme={ theme }>
      <main>
        <UnderConstruction />
      </main>
    </ThemeProvider>
  );
}
